import { Grid } from 'react-loader-spinner'

export default function FullPageSpinner() {
  return (
    <div className='h-screen bg-black text-white flex justify-center items-center'>
      <Grid
        height='80'
        width='80'
        color='#fff'
        ariaLabel='grid-loading'
        radius='12.5'
        wrapperStyle={{}}
        wrapperClass=''
        visible
      />
    </div>
  )
}
