import { Combobox, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'

import { FiSearch } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import notFoundImage from 'assets/images/404.svg'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Container from 'components/container'

const pagesData = [
  {
    path: '',
    title: 'home',
  },
  {
    path: '/insights',
    title: 'insights',
  },
  {
    path: '/services',
    title: 'services',
  },
  {
    path: '/careers',
    title: 'careers',
  },
  {
    path: '/contact-us',
    title: 'contact us',
  },
]

export default function NotFound() {
  const [selected, setSelected] = useState({})
  const [query, setQuery] = useState('')

  const filteredPeople =
    query === ''
      ? pagesData
      : pagesData.filter((person) =>
          person.title
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, '')),
        )
  return (
    <Container className='min-h-screen flex items-center'>
      <div className='grid grid-cols-2 gap-4'>
        <div className='col-span-2 lg:col-span-1 order-2 lg:order-1'>
          <h2 className='text-lg font-semibold'>404 error</h2>
          <h1 className='text-4xl font-bold py-2'>Page Not Found</h1>
          <p className='mt-2 mb-4 text-tertiary font-medium'>
            Sorry the page you are looking for does not exists or has been moved. Try searching our
            site.
          </p>
          <Combobox value={selected} onChange={setSelected}>
            <div className='relative mt-1'>
              <div className='relative w-full cursor-default overflow-hidden rounded-lg text-left focus:ring-1 focus:ring-indigo-500 bg-gray-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm'>
                <Combobox.Input
                  className='w-full bg-gray-100 border-none py-4 px-6 font-semibold text-sm leading-5 text-gray-600 focus:outline-none focus:ring-1 focus:ring-indigo-500'
                  displayValue={(person) => person.title}
                  onChange={(event) => setQuery(event.target.value)}
                  placeholder='Search our site'
                />
                <Combobox.Button className='absolute inset-y-0 right-0 flex items-center pr-3'>
                  <FiSearch className='h-5 w-5 text-gray-400' aria-hidden='true' />
                </Combobox.Button>
              </div>
              <Transition
                as={Fragment}
                leave='transition ease-in duration-100'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
                afterLeave={() => setQuery('')}
              >
                <Combobox.Options className='absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                  {filteredPeople.length === 0 && query !== '' ? (
                    <div className='relative cursor-default select-none py-2 px-4 text-gray-700'>
                      Nothing found.
                    </div>
                  ) : (
                    filteredPeople.map((person) => (
                      <Combobox.Option
                        key={person.title}
                        className={({ active }) =>
                          `relative cursor-default select-none py-3 pl-10 pr-4 text-gray-600 ${
                            active ? 'bg-gray-100' : ''
                          }`
                        }
                        value={person}
                      >
                        {({ selected }) => (
                          <Link
                            to={`${person.path}`}
                            className={`flex justify-between items-center truncate capitalize ${
                              selected ? 'font-semibold' : 'font-semibold'
                            }`}
                          >
                            {person.title} <span>&rarr;</span>
                          </Link>
                        )}
                      </Combobox.Option>
                    ))
                  )}
                </Combobox.Options>
              </Transition>
            </div>
          </Combobox>
        </div>
        <div className='col-span-2 lg:col-span-1 order-1 lg:order-2 flex justify-center items-center'>
          <LazyLoadImage effect='blur' src={notFoundImage} alt='not found' className='pb-4 w-80' />
        </div>
      </div>
    </Container>
  )
}
