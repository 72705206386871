import Footer from 'components/footer'
import FullPageSpinner from 'components/full-page-spinner'
import Error from 'pages/error'
import Router from 'pages/router'
import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'

export default function App() {
  return (
    <div>
      <ErrorBoundary fallback={<Error />}>
        <React.Suspense fallback={<FullPageSpinner />}>
          <Router />
          <Footer />
        </React.Suspense>
      </ErrorBoundary>
    </div>
  )
}
