import React from 'react'
import { Route, Routes } from 'react-router-dom'
import NotFound from './not-found'

const Home = React.lazy(() => import('./home'))
const ContactUS = React.lazy(() => import('./contact-us'))
const Services = React.lazy(() => import('./services'))
const Careers = React.lazy(() => import('./careers'))
const Insights = React.lazy(() => import('./insights'))
const ServiceDetails = React.lazy(() => import('./service-details'))
const InsightsDetails = React.lazy(() => import('./insights-details'))
const PrivacyPolicy = React.lazy(() => import('./privacy-policy'))

export default function Router() {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/contact-us' element={<ContactUS />} />
      <Route path='/services' element={<Services />} />
      <Route path='/services/:id' element={<ServiceDetails />} />
      <Route path='/insights' element={<Insights />} />
      <Route path='/insights/:id' element={<InsightsDetails />} />
      <Route path='/careers' element={<Careers />} />
      <Route path='/privacy-policy' element={<PrivacyPolicy />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  )
}
