import axios from 'axios'
import { BASE_URL } from 'baseUrl'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import Container from './container'

async function getFooterData() {
  const response = await axios.get(`${BASE_URL}/social-media`)
  return response.data
}

export default function Footer() {
  const { data, isLoading } = useQuery(['footer'], getFooterData)

  if (isLoading) {
    return <p>Footer is Loading...</p>
  }

  return (
    <footer>
      <div className='bg-[#E1DFCE]'>
        <Container className='py-28 text-center'>
          <Link
            to='/contact-us'
            className='text-4xl lg:text-5xl font-medium underline text-center mx-auto inline-block'
          >
            Contact us &rarr;
          </Link>
          <div className='mt-16 flex justify-center'>
            <div className='flex text-xl lg:text-2xl'>
              {/* <Link to='/services' className='underline'>
                Services
              </Link>
              <Link to='/insights' className='underline'>
                Insights
              </Link>
              <Link to='/careers' className='underline'>
                Careers
              </Link> */}
              <a href={data.data.instra} target='_blank' className='underline' rel='noreferrer'>
                Instagram
              </a>
              {/* <Link to='/contact-us' className='underline'>
                Contact Us
              </Link> */}
            </div>
          </div>
          <div className='mt-6 flex justify-center'>
            <div className='flex gap-4 text-xl'>
              {/* <a href={data.data.linked_id} target='_blank' className='underline' rel='noreferrer'>
                Linkedin
              </a> */}

              {/* <a href={data.data.society} target='_blank' className='underline' rel='noreferrer'>
                Luxury Society
              </a> */}
            </div>
          </div>
          {/* <p className='text-center text-xl mt-4'>Agency 44. All Rights Reserved. </p>
          <Link to='/privacy-policy' className='underline inline-block mt-4 text-center'>
            Privacy Policy
          </Link> */}
        </Container>
      </div>
      <p className='py-4  bg-black text-white flex justify-between px-4 font-medium'>
        <p>
          Copyright @{new Date().getFullYear()} Powered by{' '}
          <Link to='https://skydigitalcloud.com/' className='underline' target='_blank'>
            Sky Digital
          </Link>{' '}
        </p>
        <p>Developed by TechUniq</p>
      </p>
    </footer>
  )
}
