import errorImage from 'assets/images/500.svg'

export default function Error() {
  return (
    <div className='h-screen flex justify-center items-center'>
      <div className=''>
        <img src={errorImage} alt='Internal server error' className='w-72 h-auto mx-auto' />
        <div className='my-6 text-center'>
          <h2 className=' text-3xl font-bold leading-7 text-slate-800'>Something went wrong...</h2>
          <p className='text-lg leading-8 text-slate-600 mt-2'>
            We are working on fixing the problem
          </p>
          <p className='text-lg leading-8 text-slate-600'>Please try again</p>
          <button
            className='inline-flex items-center text-md font-semibold gap-2 mt-6 px-10 hover:bg-slate-800 py-3 bg-slate-900 rounded-full text-white'
            onClick={() => window.location.reload()}
          >
            <span>
              <svg
                className='h-4 w-4'
                stroke='currentColor'
                fill='currentColor'
                strokeWidth='0'
                version='1.1'
                viewBox='0 0 16 16'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path d='M16 6h-6l2.243-2.243c-1.133-1.133-2.64-1.757-4.243-1.757s-3.109 0.624-4.243 1.757c-1.133 1.133-1.757 2.64-1.757 4.243s0.624 3.109 1.757 4.243c1.133 1.133 2.64 1.757 4.243 1.757s3.109-0.624 4.243-1.757c0.095-0.095 0.185-0.192 0.273-0.292l1.505 1.317c-1.466 1.674-3.62 2.732-6.020 2.732-4.418 0-8-3.582-8-8s3.582-8 8-8c2.209 0 4.209 0.896 5.656 2.344l2.343-2.344v6z' />
              </svg>
            </span>
            Refresh Page
          </button>
        </div>
      </div>
    </div>
  )
}
